<template>
  <div>
    <div class="header">
      <img class="img" src="../assets/error.svg" />
      <h5 class="lead font-weight-bold mt-5">Se produjo un error al programar la cita</h5>
      <span class="lead">
        Por favor intente nuevamente.
      </span>
    </div>

  
    <div class="btn-container">
      <button type="button" class="btn-end btn btn-success" @click="finish">Volver al inicio</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["date"]),
  },
  methods: {
    finish() {
      this.$store.commit("resetAllData");
      this.$store.commit("resetInitData");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.img {
  width: 80px;
}
.btn-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.header {
  padding-top: 5%;
  text-align: center;
  margin-bottom: 2%;
}
.btn-end {
  width: 100%;
  padding: 20px;
  background: #1e7e34;
  font-size: 17px;
  font-weight: 700;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  padding: 50px;
  padding-top: 0px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.95rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.text-left {
  text-align: left !important;
}
.list-group-flush {
  border-radius: 0;
}
.text-right {
  text-align: right !important;
}
.align-self-center {
  align-self: center !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

@media (max-width: 425px) {
  .list-group {
    padding: 0;
    margin-top: 23px;
  }
  .lead {
    font-size: 1.1rem;
  }
}
</style>
